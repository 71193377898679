module.exports = {
  comm: {
    home: 'Головна',
    pricing: 'Ціни',
    blog: 'Клонування голосу та API', // 更新
    language: 'Мова:',
    select_language: 'Вибрати мову',
    txt2voice: 'Текст в мову',
    voice2txt: 'Мова в текст',
    voiceclone: 'Клонування голосу',
    video2txt: 'Відео в текст',
    footer_help: 'Потрібна допомога? Надішліть нам електронний лист:',
    footer_mail: 'tiktokaivoicetool@gmail.com',
    login: 'Увійти',
    login_desc: 'Увійдіть, щоб отримати доступ до вашого облікового запису TikTok Voice Generator',
    logout: 'Вийти',
  },
  txt2voice: {
    title: 'Генератор голосу TikTok: Створити смішні AI TikTok голоси',
    description: 'Створюйте та завантажуйте смішні AI TikTok голоси безкоштовно, такі як голос Jessie, голос C3PO, голос ghostface...',
    keywords: 'Генератор голосу TikTok, AI TikTok голос, Генератор голосу Tik Tok',
    main_title_p01: 'Генератор голосу TikTok',
    main_title_p02: 'Створити смішні TikTok голоси: голос Jessie, голос C3PO, голос ghostface',
    main_textarea_holder: 'Введіть або вставте текст тут',
    main_genvoice: 'створити',
    main_generating: 'створення',
    main_input_empty: 'будь ласка, введіть або вставте текст',
    daily_usage_limit_msg: '1) Досягнуто максимальний денний ліміт використання. Будь ласка, поверніться завтра. 2) Якщо вам терміново потрібні додаткові використання, будь ласка, надішліть нам електронного листа.',
    text_max_prompt1: 'Будь ласка, обмежте кількість слів до',
    text_max_prompt2: 'слова або менше!',
    popup_nologin_title: 'Оновіть свій план підписки',
    popup_nologin_desc: 'Розблокуйте більше часу для генерації голосу, оновивши до преміум-підписки.',
    popup_nologin_btntext: 'Переглянути плани підписки',
    popup_nosub_title: 'Оновіть свій план підписки',
    popup_nosub_desc: 'Розблокуйте більше часу для генерації голосу, оновивши до преміум-підписки.',
    popup_nosub_btntext: 'Переглянути плани підписки',
    popup_sublimit_title: 'Термін дії підписки закінчився, будь ласка, оновіть',
    popup_sublimit_desc: 'Покращіть свій досвід з нашими преміум-функціями та необмеженим доступом.',
    popup_sublimit_btntext: 'Перейти до покупки',
  },
  pricing: {
    pricing: 'Ціни',
    new_features_alert: "📣 Ми запустили послуги 🎙️ Клонування голосу та 🤖 API для платних учасників! (Після оплати напишіть на tiktokaivoicetool@gmail.com для допомоги з цими функціями). Крім того, не соромтеся писати нам щодо будь-яких запитів на налаштування😃", // 新增
    pricing_title: 'Від індивідуальних творців до великих компаній - є план для вас.',
    pricing_desc: 'Понад 100 природних, людських голосів. Понад 15 мов. Найнижча ціна для майже кожного',
    sub_free_name: 'Безкоштовно',
    sub_free_desc: 'Для осіб, які хочуть спробувати найсучасніше AI аудіо',
    sub_free_content: [
      'Ліміт 1000 символів на місяць',
      '5 генерацій на день',
    ],
    sub_starter_name: 'Стартовий',
    sub_starter_desc: 'Для творців преміум-контенту для глобальної аудиторії',
    sub_starter_content: [
      '1 клон голосу',
      'Ліміт 300,000 символів на місяць (приблизно 7 годин аудіо)',
      'Необмежені завантаження',
      'Підтримка по email протягом 72 годин',
      'Пріоритетний доступ до нових голосів та функцій',
    ],
    sub_pro_name: 'Про',
    sub_pro_desc: 'Для творців контенту, які розширюють своє виробництво',
    sub_pro_content: [
      'Доступ до API',
      '3 клони голосу',
      'Ліміт 1,000,000 символів на місяць (приблизно 24 години аудіо)',
      'Необмежені завантаження',
      'Підтримка по email протягом 48 годин',
      'Пріоритетний доступ до нових голосів та функцій',
    ],
    period_year: 'Щорічно',
    period_month: 'Щомісячно',
    period_month_short: 'міс',
    billed_year: 'Оплачується щорічно',
    most_popular: 'Найпопулярніший',
    discount_quantity: 'Економія $48',
    buy_btntext: 'Почати',
    pay_succ: 'Оплата успішна!',
    pay_succ_desc: 'Дякуємо за вашу покупку. Ваша транзакція була успішно оброблена.',
    pay_succ_btntext: 'Почати використовувати TikTok Voice',
    pay_fail: 'Оплата не вдалася!',
    pay_fail_desc: "Вибачте, ми не змогли обробити вашу транзакцію. Будь ласка, спробуйте ще раз або зв'яжіться з нашою командою підтримки (tiktokaivoicetool@gmail.com).",
    pay_fail_btntext: 'Повернутися на головну сторінку',
  },
  setting: {
    setting: 'Налаштування',
    setting_title: 'Керуйте своїм обліковим записом, використанням та підпискою тут.',
    basic_info: 'Основна інформація', // 新增，放在 setting_title 下方
    user_name: "Ім'я",
    user_email: 'Email',
    user_account: 'Обліковий запис',
    user_subscript: 'Поточний план',
    user_usage: 'Використання',
    manage_subscript: 'Керувати підпискою',
    use_limit_promote: "Ви використали 5000 з вашого місячного ліміту в 10000 символів.",
    not_logged_in: 'Ви не увійшли в систему', // 新增
    go_to_login: 'Перейти до входу >>', // 新增
  },
  login: { // 新增 login 对象，放在 setting 下方
    title: "TikTok Voice Generator",
    subtitle: "Увійдіть, щоб отримати доступ до вашого облікового запису TikTok Voice Generator",
    alreadyLoggedIn: "Ви вже увійшли в систему",
    goToHome: "Перейти на головну сторінку"
  },
  faq: {
    quest1: 'Генератор голосу TikTok - Найкращий AI TikTok інструмент голосу',
    answer1_1: 'Це інструмент генерації голосу AI TikTok, розроблений за допомогою новітньої технології tts TikTok, може створювати TikTok голоси, такі як голос Jessie (жіночий голос), голос Siri, голос ghostface, голос C3PO (робот голос), глибокий голос (оповідач), голос Skye, теплий голос, голос Bestie, голос Героя (голос Chiris), емпатичний голос, серйозний голос, голос Joey, голос Stitch, голос Stormtrooper (Star Wars), голос Rocket (Guardians of the Galaxy).',
    answer1_2: "AI TikTok голоси, які незабаром будуть підтримуватися, включають: голос Derek, голос Trickster, голос Austin Butler, голос репортера новин, голос Adam, голос собаки, голос Miley Cyrus, голос Alexa, голос інопланетянина, голос тварини, голос дитини, голос комп'ютера, голос бурундука, голос echo, голос npr.",
    answer1_3: "Крім того, інструмент генерації голосу TikTok підтримує кілька інших мов, включаючи китайську, японську, корейську, в'єтнамську, тайську, хінді, перську, російську, німецьку, французьку, румунську, чеську, іспанську, португальську, бенгальську, італійську, арабську, урду, традиційну китайську та малайську.",
    answer1_4: 'Якщо вам терміново потрібен певний голос, будь ласка, напишіть мені електронного листа.',

quest2: 'Які переваги Генератора голосу TikTok?',
    answer2_1: '- Інструмент генерації голосу TikTok може створювати різні типи голосів, які часто використовуються у відео TikTok.',
    answer2_2: '- Це найновіший генератор голосів AI, який може створювати tts TikTok голоси, схожі на людські.',
    answer2_3: '- Це зручніше для редагування відео на ПК.',
    answer2_4: '- Ви можете використовувати деякі голоси, які наразі не можна знайти в додатку tts TikTok.',

quest3: 'Як користуватися Генератором голосу TikTok?',
    answer3_1: 'Генератор голосу TikTok дуже простий у використанні:',
    answer3_2: '- Виберіть мову та акцент голосу.',
    answer3_3: '- Введіть текст, який потрібно перетворити на мову в полі введення.',
    answer3_4: '- Натисніть кнопку створення і зачекайте кілька секунд.',
    answer3_5: '- Відтворіть або завантажте AI TikTok голос.',

quest4: 'Які найвідоміші та найсмішніші голоси TikTok?',
    answer4_1: 'Найвідоміший чоловічий голос TikTok - це глибокий голос, офіційно названий оповідачем.',
    answer4_2: 'Для найсмішнішого голосу TikTok я особисто рекомендую голос ghostface і голос C3PO. Ці голоси часто використовуються для дублювання смішних відео TikTok, оскільки вони дуже легко впізнаються і можуть швидко привернути увагу глядачів.',

quest5: 'Які найнабридливіші голоси TikTok?',
    answer5_1: 'Я вважаю, що насправді немає "набридливого" голосу TikTok.',
    answer5_2: "Це більше залежить від контексту та змісту, в якому використовується голос, що може зробити деяких людей незручними. Наприклад, деякі люди можуть вважати голос Jessie набридливим, тому що він занадто часто з'являвся на TikTok протягом певного періоду. Однак через його тон і якість, це насправді фантастичний ефект голосу TikTok. Крім того, акторка голосу TikTok, яка стоїть за ним, дуже професійна і також публікує свої власні відео на TikTok.",
    answer5_3: 'Тому я рекомендую всім залишити свої турботи та упередження осторонь, кілька разів прослухати голоси з цього інструменту і терпляче знайти TikTok голосовий ефект, який найкраще підходить для вас.',

quest6: 'Як користуватися Генератором голосу TikTok для створення голосу Jessie?',
    answer6_1: '- У першому випадаючому меню інструменту генератора голосу TikTok виберіть English (US), потім у іншому випадаючому меню виберіть голос Jessie (жіночий голос).',
    answer6_2: '- Введіть текст у полі введення та натисніть кнопку створення.',
    answer6_3: '- Зачекайте кілька секунд або до десяти секунд, ви почуєте AI голос. У панелі інструментів знайдіть кнопку зі стрілкою вниз і натисніть, щоб завантажити.',

quest7: 'Як користуватися Генератором голосу TikTok для створення голосу C3PO?',
    answer7_1: '- У першому випадаючому меню інструменту генератора голосу TikTok виберіть English (US), потім у іншому випадаючому меню виберіть голос C3PO (робот голос).',
    answer7_2: '- Введіть текст у полі введення та натисніть кнопку створення.',
    answer7_3: '- Зачекайте кілька секунд або до десяти секунд, ви почуєте AI голос. У панелі інструментів знайдіть кнопку зі стрілкою вниз і натисніть, щоб завантажити.',

quest8: 'Як користуватися Генератором голосу TikTok для створення голосу ghostface?',
    answer8_1: '- У першому випадаючому меню інструменту генератора голосу TikTok виберіть English (US), потім у іншому випадаючому меню виберіть голос ghostface.',
    answer8_2: '- Введіть текст у полі введення та натисніть кнопку створення.',
    answer8_3: '- Зачекайте кілька секунд або до десяти секунд, ви почуєте AI голос. У панелі інструментів знайдіть кнопку зі стрілкою вниз і натисніть, щоб завантажити.',

quest9: 'Як користуватися Генератором голосу TikTok для створення голосу Siri?',
    answer9_1: '- У першому випадаючому меню інструменту генератора голосу TikTok виберіть English (US), потім у іншому випадаючому меню виберіть голос Siri.',
    answer9_2: '- Введіть текст у полі введення та натисніть кнопку створення.',
    answer9_3: '- Зачекайте кілька секунд або до десяти секунд, ви почуєте AI голос. У панелі інструментів знайдіть кнопку зі стрілкою вниз і натисніть, щоб завантажити.',

quest10: 'Як додати ефект голосу TikTok до мого відео TikTok?',
    answer10_1: 'Якщо ви хочете дізнатися, як використовувати офіційні голоси TikTok на TikTok, я скоро напишу спеціальну статтю на цю тему.',
    answer10_2: 'Тут я поясню, як публікувати свій голос на TikTok після його створення та завантаження з генератора голосу TikTok.',
    answer10_3: '1. Якщо ви створили TikTok голос на своєму ПК і хочете завантажити його на TikTok або в будь-яку іншу програму редагування відео на своєму телефоні, вам потрібно передати файл голосу на свій телефон. Для iPhone ви можете використовувати airDrop для передачі. Для Android телефонів я не дуже знайомий з цим процесом, але ви, безумовно, знайдете способи та інструменти для цього.',
    answer10_4: '2. Як тільки файл голосу буде передано на ваш телефон, ви можете відкрити TikTok:',
    answer10_5: '- Натисніть кнопку "+" внизу інтерфейсу, виберіть відео на своєму телефоні.',
    answer10_6: '- Після завантаження відео знайдіть квадратну піктограму з правого боку екрану, щоб увійти на сторінку редагування.',
    answer10_7: '- У нижній частині сторінки знайдіть кнопку "додати голос" і натисніть, щоб вибрати щойно переданий файл голосу.',
    answer10_8: '- У цьому інтерфейсі ви можете внести деякі налаштування до відео, потім натисніть кнопку у верхньому правому куті, щоб опублікувати його.',

quest11: 'Чи є TikTok Voice безкоштовним?',
    answer11: 'Так, це безкоштовний інструмент генерації голосу AI TikTok з тексту в мову.',

quest12: 'Де я можу створити популярний TikTok голос у своєму відео TikTok?',
    answer12: 'Ви можете відвідати https://tiktokvoice.net/, щоб додати AI TikTok голоси до свого відео.'
  },
  // host: 'http://tiktokvoice.net'
  host: 'https://tiktokvoice.net'
}